<!-- 应收账款汇总表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listShouldReceiveFundStatistics } from "@/api/arap/index";

export default {
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: listShouldReceiveFundStatistics,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          this.$select({ key: "listCustomer",option: { option: { multiple: true }}}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "firstPreRpMoney",
            label: "期初预收",
            minWidth: 155,
          },
          {
            prop: "firstPsRpMoney",
            label: "期初销售应收",
            minWidth: 155,
          },
          {
            prop: "firstRpBalance",
            label: "期初余额",
            minWidth: 180,
          },
          {
            prop: "preRpMoney",
            label: "预收金额",
            minWidth: 140,
          },
          {
            prop: "preWriteOffMoney",
            label: "预收核销金额",
            minWidth: 140,
          },
          {
            prop: "psRpMoney",
            label: "销售应收金额",
            minWidth: 140,
          },
          {
            prop: "totalRpMoney",
            label: "应收合计金额",
            minWidth: 140,
          },
          {
            prop: "psWriteOffMoney",
            label: "销售核销金额",
            minWidth: 140,
          },
          {
            prop: "totalWriteOffMoney",
            label: "应收核销金额",
            minWidth: 140,
          },
          {
            prop: "currFactRpMoney",
            label: "本期实收金额",
            minWidth: 140,
          },
          {
            prop: "preRpBalance",
            label: "预收余额",
            minWidth: 140,
          },
          {
            prop: "psRpBalance",
            label: "销售应收余额",
            minWidth: 140,
          },
          {
            prop: "totalRpBalance",
            label: "余额",
            minWidth: 140,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 140,
          },
          {
            prop: "totalFactRpMoney",
            label: "实际收款金额",
            minWidth: 140,
          },
        ],
        list: [],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
