<!-- 应付账款明细表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listShouldPaymentFundDetail } from "@/api/arap/index";

export default {
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: listShouldPaymentFundDetail,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            type: "filters",
            tip: "全部/创建人/修改人/审核人",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "createBys", label: "创建人" },
              { filter: "updateBys", label: "修改人" },
              { filter: "auditBys", label: "审核人" },
            ],
          },
          this.$select({ key: "listCustomer",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "listSimpleQuery",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "listSaleEmployee",option: { seniorSearch: true, option: { multiple: true }}}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName?  row.partnerInfo?.areaName: '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName?  row.partnerInfo?.groupName: '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 155,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 140,
          },
          {
            prop: "preRpMoney",
            label: "预付金额",
            minWidth: 140,
          },
          {
            prop: "preWriteOffMoney",
            label: "预付核销金额",
            minWidth: 140,
          },
          {
            prop: "psRpMoney",
            label: "采购应付金额",
            minWidth: 140,
          },
          {
            prop: "totalRpMoney",
            label: "应付合计金额",
            minWidth: 140,
          },
          {
            prop: "psWriteOffMoney",
            label: "采购款核销金额",
            minWidth: 140,
          },
          {
            prop: "totalWriteOffMoney",
            label: "应付核销金额",
            minWidth: 140,
          },
          {
            prop: "currFactRpMoney",
            label: "本期实付金额",
            minWidth: 140,
          },
          {
            prop: "preRpBalance",
            label: "预付余额",
            minWidth: 140,
          },
          {
            prop: "psRpBalance",
            label: "采购应付余额",
            minWidth: 140,
          },
          {
            prop: "totalRpBalance",
            label: "余额",
            minWidth: 140,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 140,
          },
          {
            prop: "totalFactRpMoney",
            label: "实际付款金额",
            minWidth: 140,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => row.deptInfo?.deptNo?  row.deptInfo?.deptNo: '',
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => row.deptInfo?.deptName?  row.deptInfo?.deptName: '',
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) => row.saleEmployeeInfo?.employeeNo?  row.saleEmployeeInfo?.employeeNo: '',
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName?  row.saleEmployeeInfo?.employeeName: '',
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
