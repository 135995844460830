<!-- 供应商对账列表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { financeCheckSupplierCheckList, financeChecksumSupplierCheckList } from "@/api/arap/index";
const typesList = ['190203','110102','110103','190205','190206']
export default {
  name: "customerCheckBill",
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: financeCheckSupplierCheckList,
        getSummaryApi: financeChecksumSupplierCheckList,
        exportOption: {
          show: true,
          exportApi: 'exportSupplierCheckList',
          exportName: '供应商对账单',
          timeout: 60*5*1000
        },
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginSettlementDate: a, endSettlementDate: b }),
          },
          this.$select({ key: "listCustomer",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "listSimpleQuery",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "listSaleEmployee",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "checkAccountStatus",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({
            key: "billTypeList",
            option: { seniorSearch: true, option: { multiple: true, dataKey:(res)=> res.data.filter(x=> typesList.includes(x.dictValue) )} },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 155,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => row.deptInfo?.deptNo?  row.deptInfo?.deptNo: '',
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => row.deptInfo?.deptName?  row.deptInfo?.deptName: '',
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) => row.saleEmployeeInfo?.employeeNo?  row.saleEmployeeInfo?.employeeNo: '',
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName?  row.saleEmployeeInfo?.employeeName: '',
            minWidth: 120,
          },
          {
            prop: "checkAccountBy",
            label: "最后对账人",
            minWidth: 140,
          },
          {
            prop: "checkAccountTime",
            label: "最近对账时间",
            minWidth: 140,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 140,
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 140,
          },
          {
            prop: "notSettleMoney",
            label: "待收款金额",
            minWidth: 140,
          },
          {
            prop: "tranBalance",
            label: "往来余额",
            minWidth: 140,
          },
          {
            prop: "actualRpMoney",
            label: "实际收款金额",
            minWidth: 140,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 140,
          },
          {
            prop: "settlementDate",
            label: "结算日期",
            minWidth: 140,
          },
          {
            prop: "overDays",
            label: "超期天数",
            minWidth: 140,
          },
        ],
        list: [],
        summary: ['billMoney', 'settleMoney', 'notSettleMoney']
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
